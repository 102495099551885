<template>
  <v-row justify="center">
    <v-col dense 
      cols="12"
      class="py-0"
    >
      <v-subheader>Search Recipes</v-subheader>
    </v-col>

    <v-col cols="12" class="py-0 align-center">
      <v-row dense>
        <v-col cols="12">
          <v-form @submit.prevent="" >
            <v-text-field dense hide-details outlined
              search-input.sync="itemToSearch"
              :loading="searchIsLoading"
              v-model="recipeSearch"
              label="Search for Recipes"
              class="align-center rounded-lg"
              clearable 
              @click:clear="clearSearch"
              color="success"
              clear-icon="mdi-close-circle-outline"
              prepend-inner-icon="mdi-magnify"
              append-outer-icon="mdi-tune"
              @click:append-outer="filterDialog = !filterDialog"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>

      <!-- <v-row justify="center" align="center">
        <v-chip-group multiple show-arrows
          v-model="filters"
          active-class="deep-purple--text text--accent-4"
        >
          <v-chip filter outlined value="popular">Show Popular Recipes</v-chip>
          <v-chip v-if="user.loggedIn" filter outlined value="mine">Show Your Recipes</v-chip>
          <v-chip filter outlined value="browse">Find new Recipes</v-chip>
          <v-chip filter outlined value="search">Search your Recipes</v-chip>
          <v-chip filter outlined value="more" @click="filterDialog = !filterDialog">
            More <v-icon right>mdi-tune</v-icon>
          </v-chip>
        </v-chip-group>
      </v-row> -->
    </v-col>

    <!-- <v-col cols="11" class="pt-4">
      <v-divider></v-divider>
    </v-col> -->
    <!-- <v-divider inset></v-divider> -->

    <v-col cols="12" class="text-center px-4">
      <v-btn rounded color="info elevation-0" to="/recipes/add">
        Add Recipe
        <v-icon right>
          mdi-plus
        </v-icon>
      </v-btn>
      <!-- <v-btn rounded color="info">
        Select Recipes
      </v-btn> -->
    </v-col>
    
    <v-col v-for="recipe in filteredRecipes" :key="recipe.id"
      cols="12" md="4"
    >
      <!-- Recipe Cards -->      
      <recipe-card :recipe="recipe" :user="user"></recipe-card>
    </v-col>

    <v-bottom-sheet v-model="filterDialog">
      <v-sheet class="px-4 py-4 rounded-xl">
        <v-row dense>
          <v-col cols="12">
            <v-subheader>Types</v-subheader>
          </v-col>
          <v-col cols="12" class="my-0">
            <v-chip-group multiple
              v-model="ingredientFilter"
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip v-for="item in types" :key="item"
                :value="item.toLowerCase()"
                filter outlined
              >
                {{item}}
              </v-chip>
            </v-chip-group>
          </v-col>

          <!-- <v-col cols="12">
            <v-subheader>Recipe Category</v-subheader>
          </v-col>
          <v-col cols="12" class="my-0">
            <v-chip-group multiple
              v-model="filters"
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip filter outlined value="popular">Popular Recipes</v-chip>
              <v-chip filter outlined value="mine">Your Recipes</v-chip>
              <v-chip filter outlined value="browse">Browse for Recipes</v-chip>
              <v-chip filter outlined value="search">Search your Recipes</v-chip>
            </v-chip-group>
          </v-col> -->

          <v-col cols="12">
            <v-subheader>Ingredient Quantity</v-subheader>
          </v-col>
          <v-col cols="12" class="my-0">
            <v-chip-group multiple
              v-model="filters"
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip filter outlined value="mostIngredients">Most Ingredients<v-icon right small>mdi-test-tube</v-icon></v-chip>
              <v-chip filter outlined value="allIngredients">All Ingredients<v-icon right small>mdi-test-tube</v-icon></v-chip> 
            </v-chip-group>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-btn class="info rounded-lg" @click="filterDialog = false">Show Results</v-btn>
            <v-btn text @click="clearFilters">Clear</v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

  </v-row>
</template>
<script>
  import RecipeCard from '../components/RecipeCard.vue'

  import { mapGetters, mapState } from 'vuex'
  
  export default {
    name: 'Recipes', 
    components: { RecipeCard },
    data: () => ({
      recipes: [],
      popularRecipes: [],
      filters: ['type'], 
      types: ['Whisky', 'Brandy', 'Vodka', 'Rum', 'Gin', 'Tequila', 'Liqueur', 'Other', 'All'],
      recipeSearch :[],
      ingredientFilter: ['all'],

      filterDialog: false,
      
      qrcodeDialog: false,


      itemToSearch: '',
      foundSearchItems: [], 
      searchIsLoading: false,
    }), 
    mounted(){
      if(!!localStorage.getItem('filtersPreferences')){
        let filtersPrefs = JSON.parse(localStorage.getItem('filtersPreferences'));
        this.filters = filtersPrefs.filters;
        this.ingredientFilter = filtersPrefs.ingredientFilter;
      }
    },
    watch: {
      // Store filters into local storage, so that they can "persist" across sessions
      filters(newFilters, oldFilters){
        
        let preferencesStorage = {
          filters: newFilters, 
          ingredientFilter: this.ingredientFilter
        };
        
        localStorage.setItem('filtersPreferences', JSON.stringify(preferencesStorage));
      }
    },
    computed: {
      ...mapState({
        storeRecipes: 'recipes', 
        storePopularRecipes: 'popularRecipes', 
        user: 'user'
      }), 

      filtersAreCleared(){
        var returnValue = false;

        if(this.filters.length == 0){
          returnValue = returnValue || true;
        }

        // If just the type is seleted && the filters just includes all , return true
        if((this.filters.includes('type') && this.filters.length == 1) && (this.ingredientFilter.length == 1 && this.ingredientFilter.includes('all'))){
          returnValue = returnValue || true; 
        } 

        return returnValue;

      },

      filteredRecipes(){
        var returnedRecipes = []
        
        /**
         * Removing this as this section of the app will just be for filtering down exisiting recipes, 
         * and not actually browsing for recipes or popular recipes 
         */
        // Get the Popular Recipes, and get search results
        // Add search results to returnedRecipes
        // if(this.recipeSearch !== null && this.recipeSearch.length >= 2 && this.filters.includes('browse')) {
        //   this.foundSearchItems.forEach( item => {  

        //     returnedRecipes.push({
        //       type: 'searchResult',
        //       ...item
        //     })
        //   })
        // }

        // Items added from API 
        // if( this.filters.includes('popular')){
        //   this.storePopularRecipes.forEach(item => {
        //     returnedRecipes.push({
        //       type: 'popular',
        //       ...item
        //     })
        //   })
        // }
        
        // Get the currently loggedIn user's recipe
        this.storeRecipes.forEach( item => {
          returnedRecipes.push(
            {
              type: 'personal',
              id: item.id,
              ...item
            });
        })
        
        // Filter down source recipes 
        // type filter
        // Essentially if the ingredients contain one of the types, return it 
        if( (this.filters.includes('type') && this.user.loggedIn) ) {
          
          returnedRecipes = returnedRecipes.filter( (item) => {
            // Make an array of ingredients mapped down
            var ingredientsToFilter = []

            item.ingredients.forEach( (ingredient) => {

              // console.log(ingredient);
              if(typeof ingredient.type == 'object'){
                // pull type
                if( ingredient.type.type.indexOf(' ') >= 0 ) {
                  ingredient.type.type.split(' ').forEach( item => {
                    ingredientsToFilter.push(item.toLowerCase());
                  })
                } else { 
                  ingredientsToFilter.push(ingredient.type.type.toLowerCase())
                }

                // Pull name 
                if( ingredient.type.name.indexOf(' ') >= 0 ) {
                  ingredient.type.name.split(' ').forEach( item => {
                    ingredientsToFilter.push(item.toLowerCase());
                  })
                } else { 
                  ingredientsToFilter.push(ingredient.type.name.toLowerCase())
                }
              }

            })
            
            // Return true if any ingredients are in the types search
            // console.log(ingredientsToFilter.some( item => this.types.includes(item) ));
            
            // If all filter is selected, then just return true to pass all recipes
            if(this.ingredientFilter.includes('all')){
              return true;
            }

            if(this.ingredientFilter.includes('other')){
              return this.ingredientFilter.some( item => !ingredientsToFilter.includes(item) );
            }
            
            // Otherwise only return true if the ingredients match the filter type
            return this.ingredientFilter.some( item => ingredientsToFilter.includes(item) );
            
          })
          
        }
        
        // Most Ingredients or All Ingredients
        if( this.filters.includes('mostIngredients') || this.filters.includes('allIngredients') ) {
          returnedRecipes = returnedRecipes.filter( (item) => {
            
            // Get the array ingredients 
            var ingredientTypes = item.ingredients.map( (ingredient) => {
              return ingredient.type;
            })
            var numberOfIngredients = ingredientTypes.length;
            
            // Count matched ingredients 
            var countFoundIngredients = 0;
            ingredientTypes.forEach( item => {
              if(this.ownsIngredient(item)){
                countFoundIngredients++;
              }
            })
            
            if( ( this.filters.includes('mostIngredients') && ( (countFoundIngredients / numberOfIngredients) > .33) ) ||  ( this.filters.includes('allIngredients') && ((countFoundIngredients / numberOfIngredients) > 1) ) ){
              return true;
            } else { 
              return false;
            }
            
          }) 
        }

        // The last step is to do the search 
        // Filter by all component pieces
        returnedRecipes = returnedRecipes.filter( item => {
          // If it's a searchResult, just pass it in
          if(item.type == 'searchResult'){ 
            return true;
          }

          // Search through the rest of the "popular" items, and "personal" items
          // console.log(JSON.stringify(item))
          /**
           * Turn the items to search by into an array, and compare that array to the other array to see if anything matches
           */
          var searchParams = [];
          if(this.recipeSearch.indexOf(' ') >= 0){
            var wordsTosearchFor = this.recipeSearch.split(' ')
            searchParams.concat(wordsTosearchFor);
          } else {
            // Just one word, so add it to the array 
            searchParams = searchParams.concat(this.recipeSearch)
          }
          // console.log(searchParams)
          
          /**
           * For some synonyms, go ahead and pull those in as well 
           * TODO: Add in the entire etymology of recieps to search down into... maybe... 
           */
          if( searchParams.includes('whiskey') ) {
            searchParams.push('whisky')
            searchParams.push('whiskey')
            searchParams.push('bourbon')
          } else if( searchParams.includes('rum') ) {
            searchParams.push('rhum')
          }
          
          // Make the regexpression to search for the items
          var searchRegex = new RegExp(`(${searchParams.join('|')})`, 'i');

          // inherently nothing matches
          var itemMatches = false;

          // For each key,value pair in a Recipe
          for(const[key, value] of Object.entries(item)){
            // console.log(`${key}: ${value}`)
            // console.log(typeof value)

            if(value != null  && typeof value == 'object'){
              for(const[key, val] of Object.entries(value)){
                // console.log(val)
                // if the item is an object
                if(typeof val == 'object'){

                  // for each value of the object's key 
                  for(const [k, v] of Object.entries(val)){

                    // if the item is an object, (for the type of the ingredient_
                    if(typeof v == 'object'){
                      // console.log(v)
                      for(const [ky, vl] of Object.entries(v)){
                        if(searchRegex.test(vl)){
                          itemMatches = itemMatches || true;
                        } 
                      }
                    }

                    if(typeof v == 'string'){
                      if(searchRegex.test(v)){
                        itemMatches = itemMatches || true;
                      } 
                    }
                  }
                }

                if(typeof val == 'string'){
                  if(searchRegex.test(val)){
                    itemMatches = itemMatches || true;
                  } else { 
                    itemMatches = itemMatches || false;
                  }
                }
              }
            }

            // If the value is a string
            if(typeof value == 'string'){
              if(searchRegex.test(value)){
                itemMatches = itemMatches || true;
              } else {
                itemMatches = itemMatches || false;
              }
            }
          }
          
          return itemMatches;
          
        })
        
        // Need to return recipes back  
        return returnedRecipes;
      }
    },
    methods: {
      search(){

        if(this.recipeSearch == null || this.recipeSearch.length == 0){
          return
        }

        //If there's already results
        if(this.foundSearchItems.length > 0 ) {
          this.foundSearchItems = []
        }  

        //If it's short
        if(this.recipeSearch <= 1) {
          this.$emit('showAlert', {text: 'You didn\'t enter enough charactrs.', type: 'warning'});
          return;
        }

        //If it's loading
        if(this.searchIsLoading) return         
        this.searchIsLoading = true

        this.fetchDebounced()
      },
      clearSearch(){
        this.foundSearchItems = []
      },
      addToRecipes(recipe){
        // console.log(recipe)
        var recipeToAdd = recipe;
        
        if(recipe.hasOwnProperty('id')){
          delete recipeToAdd.id;
        }

        if(recipe.hasOwnProperty('type')){
          delete recipeToAdd.type;
        }

        for(const [key, value] of Object.entries(recipeToAdd)){
          if(value === undefined || value === null){
            delete recipeToAdd[key];
          }
        }

        recipeToAdd.ownerId = this.user.data.id;

        // Check to see if the recipe already exists in the store
        var itemFoundInStore = this.storeRecipes.findIndex( item => item.name == recipeToAdd.name && item.ingredients.length == recipeToAdd.ingredients.length && item.steps.length == recipeToAdd.steps.length)

        if(itemFoundInStore >= 0){
          this.$emit('showAlert', {text: 'Hmm, you already have the same or similar recipe 😢', type: 'error'})
          return; 
        } 

        // Otherwise the item doesn't exist in the store, so add it to the store and remove the item from the search results. 

        // Find the index of the recipe in foundSearchItems 
        var recipeToRemove = this.foundSearchItems.findIndex(item => {
          return item.name == recipeToAdd.name && item.ingredients.length == recipeToAdd.ingredients.length && item.steps.length == recipeToAdd.steps.length;
        })

        // If it exists, remove it (so users can't add it again)
        if(recipeToRemove >= 0){
          this.foundSearchItems.splice(recipeToRemove,1);
        }

        this.$store.dispatch('addRecipe', recipeToAdd);
        this.$emit('showAlert', {text: 'Success! Enjoy your recipe in your stash!', type: 'success'})

      },
      fetchDebounced(){
        clearTimeout(this._searchTimerId)
        
        this._searchTimeId = setTimeout(() => {
          this.searchForDrink()
        }, 2000)
      },
      recipeIsEditable(recipe){
        if(recipe.type == 'searchResult'){
          return false;
        }

        if(recipe.type == 'popular'){
          return false;
        }

        return true;
        // return recipe.type != 'popular' && recipe.type == 'searchResult'
      },
      searchForDrink(){
        this.searchIsLoading = false;
      },
      clearFilters(){
        this.ingredientFilter = ['all']
        this.filters = [] 

        if(this.user.loggedIn){
          this.filters.push('mine')
        } 

        this.filters.push('popular')
        // filters: ['popular', 'mine'], 

      },
      generateQrCode(recipeId){
        var url = `${window.location.origin}/recipe/${recipeId}`
        
        var returnUrl = 'https://picsum.photos/200';
        
        QRCode.toDataURL(url, {
          type: 'image/jpeg', 
          quality: 1, 
          margin: 1, 
        }, function(err, url){
            if (err) throw err
          
            returnUrl = url
        })
        
        return returnUrl
          // .then(returnURL => {
          //   this.qrLoading
          //   return returnURL;
          // })
          // .catch(err => console.log(err)) 
      },
      webShare(recipe){
        
        const shareData = {
          title: recipe.name, 
          text: 'Try out this recipe!', 
          url: `${window.location.origin}/recipe/${recipe.id}`
        }
        
        if(navigator.canShare){
          navigator.share(shareData)
            .then(() => {
              console.log('Shared successfullly!')
            })
            .catch(
              console.error
            )
        } else {
          this.$emit('showAlert', {text: 'You can\'t share this way 😥 Try the QRCode?', type: 'info', top: true});
        }
        
      },
      copyUrl(recipeId){
        var url = `${window.location.origin}/recipe/${recipeId}`
        
        if(navigator.clipboard){
          try {
            navigator.clipboard.writeText(url)
            this.$emit('showAlert', {text: 'Copied to your clipboard! 👍', type: 'success', top: true});
          } catch (err) {
            console.error(err)
          }
        }
      },
      // Returns true if there's at least one ingredient in inventory that matches recipe
      ownsIngredient(ingredient){
        return this.$store.getters.getInventoryByIngredient(ingredient);
      }, 
      getIngredientName(ingredient){        
        var normalizedIngredientName = '';
        if(typeof ingredient.type == 'string'){
          let ingredType = ingredient.type;
          normalizedIngredientName = ingredType;
        } else if(typeof ingredient.type == 'object'){
          let ingredType = ingredient.type.name;
          normalizedIngredientName = ingredType; 
        }
        return `${ingredient.qty != null ? ingredient.qty : ''} ${ingredient.measurement} - ${normalizedIngredientName}`
      },
    }
  };
</script>